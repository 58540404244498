import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { save, load } from 'redux-localstorage-simple'
import { Provider } from 'react-redux'
import { fetchProducts } from './redux/actions/productActions'
import rootReducer from './redux/reducers/rootReducer'
import App from './App'
import './assets/scss/style.scss'
import * as serviceWorker from './serviceWorker'
import axios from 'axios';

import { composeWithDevTools } from 'redux-devtools-extension'

axios.defaults.baseURL = process.env.REACT_APP_API_URL
const store = createStore(
  rootReducer,
  load(),
  composeWithDevTools(applyMiddleware(thunk, save()))
)

// buscar produtos do arquivo json colocar para buscar no banco de dados
store.dispatch(fetchProducts())

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)

// Se você quiser que seu aplicativo funcione offline e carregue mais rápido, você pode mudar
// unregister() para register() abaixo.
serviceWorker.unregister()
