import PropTypes from 'prop-types'
import React, { Suspense, lazy } from 'react'
import ScrollToTop from './helpers/scroll-top'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { ToastProvider } from 'react-toast-notifications'
import { multilanguage } from 'redux-multilanguage'
import { connect } from 'react-redux'
import { BreadcrumbsProvider } from 'react-breadcrumbs-dynamic'
import { AuthProvider } from './auth/AuthProvider'
// Página Principal
const HomeFashionEight = lazy(() => import('./pages/home/HomeFashionEight'))

// shop pages
const ShopGridStandard = lazy(() => import('./pages/shop/ShopGridStandard'))
const ShopGridFilter = lazy(() => import('./pages/shop/ShopGridFilter'))
const ShopGridTwoColumn = lazy(() => import('./pages/shop/ShopGridTwoColumn'))
const ShopGridNoSidebar = lazy(() => import('./pages/shop/ShopGridNoSidebar'))
const ShopGridFullWidth = lazy(() => import('./pages/shop/ShopGridFullWidth'))
const ShopGridRightSidebar = lazy(() =>
  import('./pages/shop/ShopGridRightSidebar')
)
const ShopListStandard = lazy(() => import('./pages/shop/ShopListStandard'))
const ShopListFullWidth = lazy(() => import('./pages/shop/ShopListFullWidth'))
const ShopListTwoColumn = lazy(() => import('./pages/shop/ShopListTwoColumn'))

// Páginas de produtos
const Product = lazy(() => import('./pages/shop-product/Product'))
const ProductTabLeft = lazy(() => import('./pages/shop-product/ProductTabLeft'))
const ProductTabRight = lazy(() =>
  import('./pages/shop-product/ProductTabRight')
)
const ProductSticky = lazy(() => import('./pages/shop-product/ProductSticky'))
const ProductSlider = lazy(() => import('./pages/shop-product/ProductSlider'))
const ProductFixedImage = lazy(() =>
  import('./pages/shop-product/ProductFixedImage')
)

// Outras Páginas
const Contact = lazy(() => import('./pages/other/Contact'))
const MyAccount = lazy(() => import('./pages/other/MyAccount'))
const LoginRegister = lazy(() => import('./pages/other/LoginRegister'))
// const MinhaConta = lazy(() => import('./pages/other/MinhaConta'))

const Cart = lazy(() => import('./pages/other/Cart'))
const pagamento = lazy(() => import('./pages/faturamento/pagamento'))
// const Wishlist = lazy(() => import('./pages/other/Wishlist'))
const Checkout = lazy(() => import('./pages/other/Checkout'))

const NotFound = lazy(() => import('./pages/other/NotFound'))

const App = props => {
  return (
    <ToastProvider placement="bottom-left">
      <BreadcrumbsProvider>
        <AuthProvider>
          <Router>
            <ScrollToTop>
              <Suspense
                fallback={
                  <div className="flone-preloader-wrapper">
                    <div className="flone-preloader">
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                }
              >
                <Switch>
                  <Route
                    exact
                    path={process.env.PUBLIC_URL + '/'}
                    component={HomeFashionEight}
                  />

                  {/* Página Principal */}
                  <Route
                    path={process.env.PUBLIC_URL + '/home-fashion-eight'}
                    component={HomeFashionEight}
                  />

                  {/* Página de Shopping */}
                  <Route
                    path={process.env.PUBLIC_URL + '/shop-grid-standard'}
                    component={ShopGridStandard}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + '/shop-grid-filter'}
                    component={ShopGridFilter}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + '/shop-grid-two-column'}
                    component={ShopGridTwoColumn}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + '/shop-grid-no-sidebar'}
                    component={ShopGridNoSidebar}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + '/shop-grid-full-width'}
                    component={ShopGridFullWidth}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + '/shop-grid-right-sidebar'}
                    component={ShopGridRightSidebar}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + '/shop-list-standard'}
                    component={ShopListStandard}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + '/shop-list-full-width'}
                    component={ShopListFullWidth}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + '/shop-list-two-column'}
                    component={ShopListTwoColumn}
                  />

                  {/* Páginas de produtos */}
                  <Route
                    path={process.env.PUBLIC_URL + '/product/:id'}
                    render={routeProps => (
                      <Product
                        {...routeProps}
                        key={routeProps.match.params.id}
                      />
                    )}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + '/product-tab-left/:id'}
                    component={ProductTabLeft}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + '/product-tab-right/:id'}
                    component={ProductTabRight}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + '/product-sticky/:id'}
                    component={ProductSticky}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + '/product-slider/:id'}
                    component={ProductSlider}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + '/product-fixed-image/:id'}
                    component={ProductFixedImage}
                  />

                  {/* Outras Páginas */}
                  <Route
                    path={process.env.PUBLIC_URL + '/contact'}
                    component={Contact}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + '/my-account'}
                    component={MyAccount}
                  />
                  <Route
                    path={process.env.PUBLIC_URL + '/login-register'}
                    component={LoginRegister}
                  />
                  {/* <Route
                  path={process.env.PUBLIC_URL + '/minhaConta'}
                  component={MinhaConta}
                /> */}
                  <Route
                    path={process.env.PUBLIC_URL + '/cart'}
                    component={Cart}
                  />
                  {/* <Route
                    path={process.env.PUBLIC_URL + '/wishlist'}
                    component={Wishlist}
                  /> */}
                  <Route
                    path={process.env.PUBLIC_URL + '/checkout'}
                    component={Checkout}
                  />

                  <Route
                    path={process.env.PUBLIC_URL + '/not-found'}
                    component={NotFound}
                  />

                  <Route
                    path={process.env.PUBLIC_URL + '/teste_pagamento'}
                    component={pagamento}
                  />

                  <Route exact component={NotFound} />
                </Switch>
              </Suspense>
            </ScrollToTop>
          </Router>
        </AuthProvider>
      </BreadcrumbsProvider>
    </ToastProvider>
  )
}

App.propTypes = {
  dispatch: PropTypes.func
}

export default connect()(multilanguage(App))
