export const ADD_TO_CART = 'ADD_TO_CART'
export const DECREASE_QUANTITY = 'DECREASE_QUANTITY'
export const DELETE_FROM_CART = 'DELETE_FROM_CART'
export const DELETE_ALL_FROM_CART = 'DELETE_ALL_FROM_CART'

export const addToCart = (
  item,
  addToast,
  quantityCount,
  productStock,
  cartItems,
  selectedProductSize
) => {
  return dispatch => {
    try {
      let itemFound = false;
      cartItems.forEach(produtoCarrinho => {
        if (produtoCarrinho.id === item.id) {
          itemFound = true;
          if (produtoCarrinho.quantity + quantityCount <= productStock) {
            if (addToast) {
              addToast('Adicionado ao carrinho', {
                appearance: 'success',
                autoDismiss: true
              });
            }
            dispatch({
              type: ADD_TO_CART,
              payload: {
                ...item,
                quantity: produtoCarrinho.quantity + quantityCount,
                selectedProductSize: selectedProductSize || produtoCarrinho.selectedProductSize
              }
            });
          }
        }
      });

      if (!itemFound) {
        // Lógica para quando o item não é encontrado no carrinho
        dispatch({
          type: ADD_TO_CART,
          payload: {
            ...item,
            quantity: quantityCount,
            selectedProductSize: selectedProductSize ? selectedProductSize : item.produto_variacoes[0].tamanho.nome
          }
        });
        if (addToast) {
          addToast('Adicionado ao carrinho', {
            appearance: 'success',
            autoDismiss: true
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
};

// export const addToCart = (
//   item,
//   addToast,
//   quantityCount,
//   productStock,
//   cartItems,
//   selectedProductSize
// ) => {
//   const erro = []
//   return dispatch => {
//     try {
//       cartItems.forEach(produtoCarrinho => {
//         if (produtoCarrinho.id === item.id) {
//           if (produtoCarrinho.quantity + quantityCount < productStock) {
//             if (addToast) {
//               addToast('Adicionado ao carrinho', {
//                 appearance: 'success',
//                 autoDismiss: true
//               })
//             }
//             return dispatch({
//               type: ADD_TO_CART,
//               payload: {
//                 ...item,
//                 quantity: quantityCount,
//                 selectedProductSize: selectedProductSize
//                   ? selectedProductSize
//                   : item.selectedProductSize
//                   ? item.selectedProductSize
//                   : null
//               }
//             })
//             // }
//           } else {
//             if (addToast) {
//               erro.push('erro')
//               return addToast(
//                 'A quantidade selecionado é maior que o saldo do estoque.',
//                 {
//                   appearance: 'error',
//                   autoDismiss: true
//                 }
//               )
//             }
//           }
//         }
//       })
//     } catch (e) {
//       console.log(e)
//     }
//     if (erro.length === 0) {
//       dispatch({
//         type: ADD_TO_CART,
//         payload: {
//           ...item,
//           quantity: quantityCount,
//           selectedProductSize: selectedProductSize
//             ? selectedProductSize
//             : item.selectedProductSize
//             ? item.selectedProductSize
//             : null
//         }
//       })

//       if (cartItems.length === 0) {
//         if (addToast) {
//           addToast('Adicionado ao carrinho', {
//             appearance: 'success',
//             autoDismiss: true
//           })
//         }
//       }
//     }
//   }
// }

//Este método é para estar adicionando mais uma quantidade de um produto que já existe no carrinho.
export const addToCartIncrement = (
  item,
  addToast,
  cartItems,
  selectedProductSize
) => {
  const cartItem = cartItems.find(produto => produto.id === item.id);

  if (cartItem) {
    const stock = cartItem.produto_variacoes
      .filter(variacao => variacao.tamanho.nome === selectedProductSize)
      .map(variacao => variacao.produto_detalhe.produto_estoque.saldo)[0];

    if (cartItem.quantity + 1 <= stock) {
      return dispatch => {
        dispatch({
          type: ADD_TO_CART,
          payload: {
            ...item,
            quantity: 1,
            selectedProductSize: selectedProductSize || cartItem.selectedProductSize
          }
        });
      };
    } else {
      if (addToast) {
        addToast('A quantidade selecionada é maior que o saldo do estoque.', {
          appearance: 'error',
          autoDismiss: true
        });
      }
    }
  }
};



//retirado do carrinho
export const decreaseQuantity = (item, addToast) => {
  return dispatch => {
    if (addToast) {
      addToast('Item retirado do carrinho', {
        appearance: 'warning',
        autoDismiss: true
      })
    }
    dispatch({ type: DECREASE_QUANTITY, payload: item })
  }
}
//Remover do carrinho
export const deleteFromCart = (item, addToast) => {
  return dispatch => {
    if (addToast) {
      addToast('Removido do carrinho', {
        appearance: 'error',
        autoDismiss: true
      })
    }
    dispatch({ type: DELETE_FROM_CART, payload: item })
  }
}
//Removido tudo do carrinho
export const deleteAllFromCart = addToast => {
  return dispatch => {
    if (addToast) {
      addToast('Removido tudo do carrinho', {
        appearance: 'error',
        autoDismiss: true
      })
    }
    dispatch({ type: DELETE_ALL_FROM_CART })
  }
}

// obter estoque de item do carrinho
export const cartItemStock = (item, size) => {
  if (item.produto_variacoes) {
    const variacao = item.produto_variacoes.find(variacao => variacao.tamanho.nome === size);
    if (variacao && variacao.produto_detalhe.produto_estoque) {
      return variacao.produto_detalhe.produto_estoque.saldo;
    }
  }
  return 0; 
};
