import { SET_CURRENCY } from "../actions/currencyActions";

const initState = {
  currencySymbol: "R$",
  currencyName: "BR",
  currencyRate: 1
};

const currencyReducer = (state = initState, action) => {
  if (action.type === SET_CURRENCY) {
    const currencyName = action.payload.currencyName;

      return {
        ...state,
        currencySymbol: "R$",
        currencyRate: action.payload.currencyRate,
        currencyName
      };
   
  }

  return state;
};

export default currencyReducer;
